import "./App.css";
import Dashboard from "./components/pages/zakat/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Login from "./components/pages/Register/Login";
import React, { Suspense, lazy, useState, useEffect } from "react";
import {
  BrowserRouter as Router,Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { UserProvider, UserContext } from './components/Utills/UserContext';
import { PlanModal } from "./components/pages/Disclaimer/DisclaimerModal";
// import FetchData from '../../fetchapi/Apifetch';
import FetchData from '../src/components/fetchapi/Apifetch';
// Lazy load components
const Screener = lazy(() => import("./components/pages/Screenerpage/Screener"));
const Plan = lazy(() => import("./components/pages/Plans/Plan"));
const Watchpage = lazy(() => import("./components/pages/watchpage/watchpage"));
const Setting = lazy(() => import("./components/pages/Settingpage/Setting"));
const Register = lazy(() => import("./components/pages/Register/Register"));
const Purificationcaluculator = lazy(() => import("./components/pages/Purificationcalculator/Purificationcaluculator"));
const Newdashboard = lazy(() => import("./components/pages/Darkdashboard/Newdashboard"));
const Newstock = lazy(() => import("./components/pages/Stockdetail/Newstock"));
const ZakatCalculator = lazy(() => import("./components/pages/zakat/ZakatCalculator"));
const Bestscreenner = lazy(() => import("./components/pages/Bestscreenner/Bestscreenner"));
const Forgotpassword = lazy(() => import("./components/pages/Register/Forgotpassword"));
const Otpverification = lazy(() => import("./components/pages/Register/Otpverification"));
const Newsettingpage = lazy(() => import("./components/pages/Settingpage/Newsettingpage"));
const Iopscreen = lazy(() => import("./components/pages/Iposcreen/Iposcreen"));
const Zakathcomingsoon = lazy(() => import("./components/pages/commingsoon/ZakatCalculator"));
const Chart = lazy(() => import("./components/pages/Community/Chart"));
const Industrybasestock = lazy(() => import("./components/pages/Industrybasestock/Industrybasestock"));
const Similarstock = lazy(() => import("./components/pages/Similarstocks/Similarstocks"));
const Premium = lazy(() => import("./components/pages/commingsoon/perimum"));
const Passwordscreen = lazy(() => import("./components/pages/Register/Passwordscreen"));
const Privacypolicy = lazy(() => import("./components/pages/policys/Privacypolicy"));
const Refundpolicy = lazy(() => import("./components/pages/policys/Refundpolicy"));
const Termsandcondition = lazy(() => import("./components/pages/policys/Teamsandcondition"));
const Contactus = lazy(() => import("./components/pages/Contactus/Contactus"));
const ScreeningCriteria = lazy(() => import("./components/pages/ScreeningCriteria/OurScreeningCriteria"));
const OurShariahBoard = lazy(() => import("./components/pages/ScreeningCriteria/OurShariahBoard"));
// User Context remains as it is


function App() {

  const Storedvalue = JSON.parse(localStorage.getItem("email"));
  const profiledetails = Storedvalue?.user?._id;
  const Oldprofiledetails = Storedvalue?._id
  useEffect(()=> {
    const Checkuser = async () => {
        
      try {
        const res = await FetchData(`usercachecheck/${profiledetails ? profiledetails : Oldprofiledetails}`, 'GET', null, true, false);
        if (res) {
         const jsonLoginData = JSON.stringify(res.data);
         localStorage.setItem("email", jsonLoginData);
        }
      } catch (error) {
        console.log("error",error?.status )
        if (error?.status == 404) {
          // console.error("User not found");
          // alert("User not found");
          localStorage.clear();
          window.location.reload();
        } else {
          console.error("An unexpected error occurred:", error.message);
        }
      }
    };
    if (window.location.pathname != '/' && window.location.pathname != '/register') {
   Checkuser();
    }
  },[]);






  // const token = JSON.parse(localStorage.getItem("login"));
  const isHomePage = window.Location.pathname === "/login";
  const Secure = (Component) => (
  <UserContext.Consumer>
    {({ Premium,user,theme }) => (user ? ( 
    <div style={isHomePage ? {} : { backgroundColor: theme ? "#171717" : "#fafafa" }}>
      {/* {JSON.stringify(Premium)} */}
     { Premium == true ? <Component /> : <Plan />}
      </div>
  ): <Navigate to="/" />)}
  
  </UserContext.Consumer>
  );  



  

  const InSecure = (Component) => (
  <UserContext.Consumer>
    {({ user,theme }) => (user ? <Navigate to="/dashboard" /> : (
    <div style={isHomePage ? {} : { backgroundColor: theme ? "#171717" : "#fafafa" }}>
      <Component /> 
    </div>
    ))}
  </UserContext.Consumer>
  );

  const routes = [

    // { path: "/", element:<Homepage /> },
    { path: "/", element: InSecure(Login) },
    { path: "/forgotpassword", element:InSecure(Forgotpassword)},
    { path: "/otpverification", element:InSecure(Otpverification)},
    { path: "/password", element:InSecure(Passwordscreen)},
    { path: "/dashboard1", element: Secure(Dashboard) },
    { path: "/purification", element: Secure(Purificationcaluculator) },
    { path: "/Zakat", element: Secure(Zakathcomingsoon) },
    // { path: "/Zakat", element: Secure(ZakatCalculator) },
    { path: "/screener", element: Secure(Screener) },
    { path: "/plan", element: Secure(Plan) },
    { path: "/ipo", element: Secure(Bestscreenner) },
    { path: "/iposcreen", element: Secure(Iopscreen) },
    { path: "/watchlist", element: Secure(Watchpage) },
    { path: "/profile", element: Secure(Setting) },
    { path: "/register", element: InSecure(Register) },
    { path: "/dashboard", element: Secure(Newdashboard) },
    { path: "/stock", element:Secure(Newstock)},
    { path: "/userprofile", element:Secure(Newsettingpage)},
    { path: "/community", element:Secure(Chart)},
    { path: "/chat2", element:Secure(Chart)},
    { path: "/industry", element:Secure(Industrybasestock)},
    { path: "/similarstock", element:Secure(Similarstock)},
    { path: "/premium", element:Secure(Premium)},
    { path: "/privacy-policy", element:<Privacypolicy />},
    { path: "/refund-policy", element:Secure(Refundpolicy)},
    { path: "/terms_conditions", element:<Termsandcondition />},
    { path: "/our_screening_criteria", element:Secure(ScreeningCriteria)},
    { path: "/our_shariah_board", element:Secure(OurShariahBoard)},
    { path: "/contact-us", element:<Contactus />},

  ];
  return (
    <Suspense
    fallback={
      <div className="fallback-container">
        <div className="animated-logo">
          <img src="/logo1.webp" alt="Loading..." />
        </div>
        <div className="loading-text">Loading, please wait...</div>
      </div>
    }
  >
    <Router>
      <UserProvider>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      </UserProvider>
    </Router>
    </Suspense>
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react';
import "./css/login.css"
import { UserContext } from '../../Utills/UserContext';

const Common = (page) => {
 console.log('page',page.page);
    const { theme, setTheme } = useContext(UserContext);
  return (
    <>
            <img src="images/loginpage.png" alt='logo' className='background-img' />
            <div className='text-overlay'>
              <img src="logonew.png" alt='logo' className='mt-1 logo-image' />
              <img src={"images/app/itg-logo1.png"} alt="Logo" className='logo2' />
              <p className='paratext px-4'>
                At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles.
                Pure Profits. Absolute Peace of Mind.
                Invest the Right Way—Shariah Compliant, Always. </p>
              <div className='mt-3'>
                <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
                  <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='images/facebook.png' className='p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={38} height={38} />
                  </a>
                  <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='images/insta.png' className=' p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={40} height={40} />
                  </a>
                  <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='images/twitter.png' className=' p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='images/youtube.png' className='  p-2 ' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='images/linkedin.png' className='p-2' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://whatsapp.com/channel/0029VakHKaFJENy4EzxloA13" target="_blank" rel="noopener noreferrer">
                    <img src='images/whatsapp.png' className='p-2' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                  <a href="https://t.me/islamictijarat" target="_blank" rel="noopener noreferrer">
                    <img src='images/islamic/telegram.png' className='p-2' style={{ backgroundColor: "white", borderRadius: '50%' }} width={35} height={35} />
                  </a>
                </div>
                    {page.page ==  "register" &&
                    <>
                    <p className='paratext mt-3'>Do you have an account</p>
                    <a className='paratext btn-card text-white' onClick={() => { window.location.href = "/" }} >Sign In?</a>
                    </>
                }
               {page.page ==  "login" &&
                <>
                <p className='paratext mt-3'>Don’t have an account</p>
                <a className='paratext btn-card text-white' onClick={() => { window.location.href = "/register" }} >Sign Up?</a>
                </>
                 }
              </div>
            </div>
    </>
    
  )
}

export default Common
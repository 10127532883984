// DisclaimerModal.js
import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DisclaimerModal.css'; // Import a CSS file for additional styles
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from '../../Utills/UserContext';
import FetchData from '../../fetchapi/Apifetch';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "../Plans/style.css"

const DisclaimerModal = ({ showModal, handleClose }) => {
    const [isChecked, setIsChecked] = useState(false); // State for the checkbox

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); 
    };

    const handleAgree = () => {
        if (isChecked) {
            handleClose(); // Close the modal if the checkbox is checked
        } else {
            alert("Please agree to the terms before proceeding.");
        }
    };

    return (
        <Modal show={showModal}  centered className="disclaimer-modal">
            <Modal.Header >
                <Modal.Title className="modal-title-value">Disclaimer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-value">
                <h5>Welcome to Islamic <span className="brand-color">Tijarat</span> <sup className="trademark">TM</sup></h5>
                <p>
                    Before proceeding, please read this disclaimer carefully. By using our website, you acknowledge that you have understood and agreed to the terms mentioned below.
                </p>
                {/* Rest of the disclaimer text */}
                <h6>Educational and Informational Purposes Only</h6>
                <p>
                    The content provided on Islamic Tijarat  is for <strong>educational and Shariah-awareness purposes</strong> only. The information, tools, and resources available on this platform are intended to offer guidance based on Shariah compliance standards. This information should not be considered as a recommendation, endorsement, or solicitation to engage in any form of trading, investment, or financial decision-making.
                </p>
                <h6>No Investment Advice</h6>
                <p>
                    Islamic Tijarat does not provide financial or investment advice. We do not recommend or advise the purchase, holding, or sale of any securities. Users are advised to seek independent financial advice from qualified professionals before making any investment decisions.
                </p>
                <h6 className='fw-bold'>Accuracy of Information</h6>
                <p>
                    While we strive to ensure that the information provided on our platform is accurate and up-to-date, <strong>Islamic Tijarat does not guarantee the accuracy, reliability, or completeness of the data displayed</strong>. The Shariah compliance status, financial ratios, and other metrics shown on the platform are subject to changes and may vary from the actual figures. Islamic Tijarat and its owners, partners, and associates are not responsible for any discrepancies or inaccuracies.
                </p>
                <h6 className='fw-bold'>No Liability</h6>
                <p>
                    Islamic Tijarat , its owners, affiliates, partners, and contributors will not be held liable for any losses, damages, or legal actions arising from the use of this platform. This includes, but is not limited to, investment losses, financial damages, or trading errors. All users are responsible for their own actions and decisions based on the information provided on the website.
                </p>
                <h6 className='fw-bold'>User Responsibility</h6>
                <p>
                    It is the responsibility of each user to verify the information presented and conduct their own research before making any decisions. By accessing and using Islamic Tijarat, you agree to release the platform and its affiliates from any and all legal liabilities associated with the use of the content or tools available.
                </p>
                <h6>Amendments and Updates</h6>
                <p>
                    Islamic Tijarat  reserves the right to amend, update, or change this disclaimer and other policies at any time without prior notice. Continued use of the platform constitutes acceptance of these changes.
                </p>
                <h6>Governing Law</h6>
                <p>
                    This disclaimer and any disputes arising out of or in connection with it shall be governed by and construed in accordance with the laws of <strong>Mumbai, India</strong>.
                </p>
                <p>
                    By proceeding to use this website, you agree to the above terms and conditions. If you do not agree, please exit the website immediately.
                </p>
                <p>
                    For any further clarifications or queries, feel free to reach out to us at:<br />
                    📧 <strong>info@islamictijarat.com</strong>
                </p>

                {/* Checkbox for agreeing to the terms */}
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="agreementCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="agreementCheckbox">
                        I have read and agree to the terms and conditions.
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="primary" 
                    onClick={handleAgree} 
                    disabled={!isChecked} 
                    className="agree-button"
                >
                    I Agree
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


const PremiumModal = ({ showModal, handleClose,hide }) => {
    const navigate = useNavigate()

    const handleAgree = () => {
        window.location.href='/plan';
    };

    return (
        <Modal show={showModal}  size="xl" onHide={handleClose} centered dialogClassName="premium-modal large-modal">
           
            <Modal.Body className="modal-body-value p-5"  style={{
        backgroundImage: 'url(/images/islamic/model.webp)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        borderRadius: '10px', 

    }} >
                {/* <div className="modal-content-container">
                <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="fw-bold">
          Premium<span className="brand-color"> Features</span>
        </h2>
        <button
          type="button"
          className="btn-close pointer m-0"
          aria-label="Close"
          onClick={handleClose}
          style={{
            background: 'transparent',
            border: 'none',
            fontSize:'35px',
            color: '#000',
          }}
        >
          &times;
        </button>
      </div>

                   
                    <p className=' fw-bold'>
                    Exclusive Discount: Special rates for Muftis, Aalims, Huffaz and Imams.
                    Contact us at admin@islamicstock.in for details.
                    </p>
                    <div className="disclaimer-content">
                        <p>
   <p className='fw-bold'>
Contact us at admin@islamicstock.in for details.
</p>                       

<div className="disclaimer-content row">

    <div className='col-lg-6 col-md-6 col-sm-12'>
    <ul className="features-list text-dark">
    <li> Find momentum stocks with one click.</li>
    <li> Find stocks with different screeners and strategies that suit your investing style.</li>
    <li>Track & Add momentum stocks to your watchlist.</li>
    <li>Export the screener data.</li>
    <li>Get real-time notifications on your mobile for breakouts with trigger prices.</li>
    <li>Check stocks that appeared in the max screeners.</li>
    <li>Move to nested screener from current screener.</li>

    <li>Create up to 6 watchlists and add up to 50 stocks to each watchlist.</li>
    <li>Track yours or others analysis and maintain them in the portfolio.</li>
    <li>Create up to 6 portfolios and add up to 30 stocks to each portfolio to track your different investing style.</li>

    <li>Keep your records saved in the cloud. Even if you change your mobile, all your previous data will be available with the sign-in.</li>
    <li>Check recently updated stocks.</li>
    <li>Get advance filter from screener tab.</li>
    <li>In the stocks detailed page, check</li>
    <li>screener, bulk deal & old Shari'ah status.</li>
    <li>And many more upcoming features will be available in the premium features.</li>
  </ul>
    </div>
    <div  className='col-lg-6 col-md-6 col-sm-12'>
        <img src="/images/contentmodel.png" style={{width:"100%"}} />

    </div>

</div>



                        </p>
            
                    </div>
                </div> */}
                 <div className="modal-content-container">
                <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="fw-bold">
        Premium <span className="brand-color"> Features</span>
        </h2>
        <button
          type="button"
          className="btn-close pointer m-0"
          aria-label="Close"
          onClick={handleClose}
          style={{
            background: 'transparent',
            border: 'none',
            fontSize:'35px',
            color: '#000',
          }}
        >
          &times;
        </button>
      </div>

                   
            
                    {/* Disclaimer Content */}
                    <div className="disclaimer-content">
                        <p>    
<div className="disclaimer-content row">
    <div className='col-lg-7 col-md-7 col-sm-12'>
        <div className='row'>
 
     <ul className="features-list text-dark custom-scrollbar" style={{
    height: "400px", 
    overflowY: "auto", 
    paddingRight: "10px" // Add space to avoid clipping with scrollbar
  }}>
    <li> Detailed Shariah Screening </li>
    <div className='discription-content ms-4 p-1 mb-3 mt-0'> 
       In-depth compliance breakdown for every stock, including detailed financial ratios and specific compliance criteria.
    </div>
    <li>ITG Shariah Rating</li>
    <div className='discription-content ms-4 p-1 mb-3 mt-0'> 
    Proprietary Shariah rating system providing a star-based compliance overview for each stock.</div>
    <li>Detailed Filters </li>
    <div className='discription-content ms-4 p-1 mb-3 mt-0'>  
    Advanced stock filtering based on financial metrics, Shariah compliance, and user preferences.</div>

    <li>Detailed Stock Overview </li>
    <div className='discription-content ms-4  p-1 mb-3 mt-0'> 
    Comprehensive data including fundamentals, technicals, SWOT analysis, and growth projections.</div>

    <li>Priority Support </li>


    <div className='discription-content ms-4 p-1 mb-3 mt-0'> 
    Access to faster, priority-based support channels.</div>




    <li>One-on-One Shariah Consultation  </li>


    <div className='discription-content  ms-4 p-1 mb-3 mt-0'> 
    Personalized Shariah compliance guidance with experts.</div>

    <li>Shariah Portfolio Overview</li>
    <div className='discription-content  ms-4 p-1 mb-3 mt-0'> 
    Detailed view of your portfolio’s compliance status and purification needs.</div>

    <li>Community Access</li>
    <div className='discription-content ms-4 mb-3 p-1 mt-0'> 
    Join an exclusive community of ethical traders and investors.</div>

    <li>Access to Shariah Stocks in Major Indices</li>
    <div className='discription-content  ms-4 mb-3 p-1 mt-0'> 
    Full access to Shariah-compliant stocks across all major indices.</div>


    <li>Premium Webinars and Lectures </li>
    <div className='discription-content  ms-4 mb-3 p-1 mt-0'> 
    Attend exclusive webinars and lectures from industry and Shariah experts.
</div>
    <li> Extended Watchlists</li>

  <div className='discription-content ms-4 mb-3 p-1 mt-0'> 
    Create up to 6 watchlists with a maximum of 50 stocks each.</div>
    
    <li> Unified Login for Mobile and Desktop with Cloud Storage </li>
    <div className='discription-content ms-4 mb-3 p-1 mt-0'> 
    Sync and access your data seamlessly across devices; just log in and start using from anywhere.</div>

   
  </ul>
   
     </div>
   
    </div>
    <div  className='col-lg-5 col-md-5 col-sm-12 m-auto'>
        <img src="/images/contentmodel.webp" style={{width:"100%"}} />

    </div>

</div>



                        </p>
                        {/* Add additional sections as necessary */}
                    </div>
                </div>


 <div className='text-center mt-3'>
  {!hide &&  
                <Button
                    onClick={handleAgree}
                  
                    className="subscriptionbutton"
                >
               View Pricing Plans
                </Button>
}
                </div>
            </Modal.Body>
            
        </Modal>
    );
};

const LogoutModal = ({ show, onClose, onLogout, theme }) => {
    return (
      <Modal
        show={show}
        onHide={onClose}
        centered
        // style={{
        //   backgroundColor: theme ? "#333" : "white", // Dark theme background color
        //   color: theme ? "white" : "black", // Dark theme text color
        // }}
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: theme ? "#7E66BC" : "#5b298c", // Dark header background
            color:"#fff", // Header text color
          }}
        >
          <Modal.Title >Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: theme ? "#444" : "white", // Dark theme body background
            color: theme ? "white" : "black", // Dark theme body text color
          }}
        >
          <p>Are you sure you want to log out?</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: theme ? "#1c1c1c" : "#f8f9fa", // Dark theme footer background
            color: theme ? "white" : "black", // Dark theme footer text color
          }}
        >
          <Button
            variant="secondary"
            className='btn-sm'
            onClick={onClose}
            style={{
              backgroundColor: "#6c757d", // Grey color for cancel button
              borderColor: "#6c757d",
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
               className='btn-sm'
            onClick={onLogout}
            style={{
              backgroundColor: "#5b298c", // Dark purple color for logout button
              borderColor: "#5b298c",
              color: "white",
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const WatchlistModal = ({ showModal, handleClose, theme }) => {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        style={{
          backgroundColor:"#161515b0", // Dark header background
          color:"#fff", // Header text color
        }}
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: theme ? "#7E66BC" : "#5b298c", // Dark header background
            color:"#fff", // Header text color
          }}
        >
          <h3 style={{fontSize:"20px"}} >Watchlist Limit Reached</h3>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "white", // Dark theme body background
            color:"black", // Dark theme body text color
          }}
        >
          <p> The maximum number of stocks allowed in this watchlist has been reached. Please create a new watchlist to add more stocks. </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#f8f9fa", // Dark theme footer background
            color: "black", // Dark theme footer text color
          }}
        >
          <Button
            variant="secondary"
            className='btn w-25'

            onClick={handleClose}
            style={{
              backgroundColor: "#6c757d", // Grey color for cancel button
              borderColor: "#6c757d",
              color: "white",
            }}
          >
            OK
          </Button> 
        </Modal.Footer>
      </Modal>
    );
  };

export { DisclaimerModal, PremiumModal, LogoutModal, WatchlistModal};

import React, { createContext, useState, useEffect ,useContext} from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Create the context
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Use lazy initialization to set the initial state from localStorage

  const [user, setUser] = useState(() => {
    const storedUser = JSON.parse(localStorage.getItem("login"));
    return storedUser || null;
  });
  
const [storedFilters,setStoredFilter] = useState(JSON.parse(localStorage.getItem("filters")) || null);


  const [Premium, setPremium] = useState(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      try {
        const emailData = JSON.parse(storedEmail);
        return emailData?.user?.subscriptionstatus || null;
      } catch {
        return null;
      }
    }
    return null;
  });

  const [theme, setTheme] = useState(() => {

    const theme = JSON.parse(localStorage.getItem("theme"));
    return theme || null;
  });


  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      try {
        const emailData = JSON.parse(storedEmail);
        setPremium(emailData?.user?.subscriptionstatus || null);
      } catch {
        setPremium(null);
      }
    }
  }, [user]);
    // const [theme, setTheme] = useState(true)



  // Save user to localStorage when it changes
  useEffect(() => {
    if (user) {
      localStorage.setItem("login", JSON.stringify(user));
    } else {
      localStorage.removeItem("login");
    }
  }, [user]);
  


  useEffect(() => {
      localStorage.setItem("theme", theme);
  }, [theme]);

  // Define the context value
  const value = {
    user,
    setUser,
    Premium,
    theme, setTheme,
    storedFilters,setStoredFilter
  };

  // Provide the context to children components
  return (
    <UserContext.Provider value={value} >
      {children}
    </UserContext.Provider>
  );
};



